<template>
  <div>
    <Form :data="item" :isSubmitting="isSubmitting" :readOnly="true" />
  </div>
</template>

<script>
import { onMounted } from '@vue/composition-api/dist/vue-composition-api'
import Form from '../shared/Form.vue'
import useProcessEdit from '../edit/useProcessEdit'

export default {
  components: {
    Form,
  },
  setup() {
    const { show, item, isSubmitting } = useProcessEdit()

    onMounted(show)

    return {
      show,
      item,
      isSubmitting,
    }
  },

}
</script>
